<template>
  <div v-if="dialog && users">
    <v-dialog
      :value="dialog"
      max-width="1200"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card >
        <v-toolbar >
          <v-btn icon  @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="pl-5">
            <v-col cols="6" sm="6" md="6" class="text-left mt-4">
              <v-toolbar-title >{{ this.reportUrl=="getViewAgent" ? "List Master Statement": this.reportUrl=="getViewPlayer"? "List Agent Statement" : this.reportUrl=="getViewPlayerDetail"? "List Player Statement" : "Loading ..." }}</v-toolbar-title>
            </v-col>       
            <!-- <v-col cols="2" sm="2" md="2" class="text-right">
              <v-menu
                v-model="v_startDate"
                :close-on-content-click="false"
                :nudge-right="100"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="ចាប់ផ្តើម"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker v-model="startDate" @input="v_startDate = false">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col  cols="2" sm="2" md="2" class="text-right">
              <v-menu
                v-model="v_endDate"
                :close-on-content-click="false"
                :nudge-right="100"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    label="បញ្ចប់"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="v_endDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col> -->
            <v-col cols="4" sm="4" md="4" class="text-right">
              <v-text-field
                v-model="v_search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="mr-5"
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col  cols="2" sm="2" md="2" class="text-right">
              <v-btn
                class="white--text btn-custom mr-4"
                color="green darken-1"
                depressed
                @click="search"
              >
                <!-- @click="fetchListStatement(params)" -->
                SEARCH
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            style="font-family: 'khmer mef1'"
            class="elevation-1"
            item-key="_id._id"
            
            :headers="headers"
            :footer-props="footerProps"
            :items="users ? users.object : []"
            :server-items-length="users ? users.objectCount : null"
            :sort-desc.sync="dataRequest.descendingFormat"
            :sort-by.sync="dataRequest.sortByFormat"
            :page.sync="dataRequest.page"
            :items-per-page.sync="dataRequest.rowsPerPage"
            @update:page="$emit('getData3')"
            @update:items-per-page="$emit('getData3')"
            @update:sort-desc="$emit('getData3')"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item`]="{ item }">
              <tr>
                <td> 
                    <v-icon
                    v-if="user.role_name == 'COMPANY'"
                    color="green"
                    small
                    class=""
                    @click="getUserChild5(item)"
                    >mdi-eye
                  </v-icon>
                  <v-icon
                    v-if="user.role_name != 'COMPANY'"
                    color="green"
                    small
                    class=""
                    @click="getUserChild4(item)"
                    >{{reportUrl ? "mdi-eye" :"mdi-eye-off"}} 
                  </v-icon>
                    </td>
              <td>{{users.object.indexOf(item) +1 }}</td>
                <td>{{ item._id.user_name }} </td>
                <td>{{ currencyFormat(item._id.balance) }}</td>
                <td class="amount-bet-color">{{ currencyFormat(item.amount) }}</td>
                <td class="win-color">{{ currencyFormat(item.amount_win) }}</td>
                <td class="lose-color">{{ currencyFormat(item.amount_lose) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <statement-list4
      v-if="user.role_name !='COMPANY'"
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild4"
      :data-request="data4"
      :user="user"
      :date="date"
      v-on:getData4="getUserChild4(item)"
      @onSearch="onSearch"
      @onCloseDialog="dialogListUser = !dialogListUser"
    />
    <statement-list5
      v-if="user.role_name =='COMPANY'"
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild5"
      :data-request="data5"
      v-on:getData5="getUserChild5(item)"
      :date="date"
      @onSearch="onSearch"
      :user="user"
      @onCloseDialog="dialogListUser = !dialogListUser"
    />
  </div>
</template>

<script>
import headers from "../../_api/columns";
import StatementList4 from "./Dialog4";
import StatementList5 from "./Dialog5";

export default {
  components: { StatementList4,StatementList5 },
  props: ["darkmode", "dialog", "users", "dataRequest","user","date"],
  data: () => {
    return {
      dialogListUser: false,
      headers: headers,
      userChild4: [],
      userChild5:[],
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200]
      },
      item: null,
      data4: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["_id"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["_id.user_name", "_id.balance", "amount", "amount_win"],
        search: "",
        startDate:  new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
      },
        data5: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["_id"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["sn_number.name", "sn_number.desc", "sn_number.payout", "date", "fight_no", "is_win", "amount", "amount_win", "cast"],
        search: "",
        startDate:  new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
      },
      v_startDate: false,
      v_endDate: false,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      v_search: "",
      params: ""
    };
  },
  computed: {
   reportUrl: function(){
     if(this.user){
        if(this.user.role_name == "DEVELOPER") return "getViewPlayer"
        else if(this.user.role_name == "COMPANY") return "getViewPlayerDetail"
        else if(this.user.role_name == "Manager") return "getViewPlayerDetail"
        else return null
     }
     else return null
  }
 },
  methods: {
    getUserChild4(item, search="") {
      if(search) this.data4.search = search
      else  this.data4.search = ""
      if(this.date){
        this.data4.startDate = this.date.startDate
        this.data4.endDate = this.date.endDate
      }
      this.item = item;
      this.data4.sortByFormat.length < 1
        ? (this.data4.sortBy = "_id")
        : (this.data4.sortBy = this.data4.sortByFormat[0]);
      this.data4.descendingFormat.length > 0
        ? (this.data4.descending = this.data4.descendingFormat[0])
        : (this.data4.descending = true);
      this.$request
        .post({
          url: 'adminReport/'+this.reportUrl+'/'+ this.item._id._id,
          data: this.data4
        })
        .then(res => {
          if (res.data.code) {
            this.userChild4 = res.data.data;
            this.dialogListUser = true;
          }
        });
    },
     getUserChild5(item, search="") {
      if(search) this.data5.search = search
      else  this.data5.search = ""
      if(this.date){
        this.data5.startDate = this.date.startDate
        this.data5.endDate = this.date.endDate
      }
      this.item = item;
      this.data5.sortByFormat.length < 1
        ? (this.data5.sortBy = "_id")
        : (this.data5.sortBy = this.data5.sortByFormat[0]);
      this.data5.descendingFormat.length > 0
        ? (this.data5.descending = this.data5.descendingFormat[0])
        : (this.data5.descending = true);
      this.$request
        .post({
          url: 'adminReport/'+this.reportUrl+'/'+ this.item._id._id,
          data: this.data5
        })
        .then(res => {
          if (res.data.code) {            
            this.dialogListUser = true;
            this.userChild5 = res.data.data;
          }
        });
    },
    onSearch(search) {
      if (this.user.role_name=="COMPANY") this.getUserChild5(this.item, search)
      else this.getUserChild4(this.item, search)
    },
    search() {
      this.$emit('onSearch', this.v_search)
    },
    currencyFormat (num) {
      num = parseFloat(num)
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      // return parseInt(num).toLocaleString('en')
    },
  }
};
</script>

<style scoped>
  .btn-custom {
    position: relative;
    top: 10px;
  }
  .amount-bet-color{
  color: goldenrod!important
}
.win-color{
    color: rgb(57, 175, 214);
    font-weight: bold
  }
  .lose-color{
    color:#BA2D4A;
    font-weight:bold
  }
</style>
