<template>
  <div v-if="dialog && users">
    <v-dialog :value="dialog" max-width="1200" persistent transition="dialog-bottom-transition">
      <v-card >
        <v-toolbar >
          <v-btn icon  @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
           <v-row class="pl-5">
            <v-col cols="6" sm="6" md="6" class="text-left mt-4">
              <v-toolbar-title >Detail Player Statement</v-toolbar-title>
            </v-col>       
            <v-col cols="4" sm="4" md="4" class="text-right">
              <v-text-field
                v-model="v_search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="mr-5"
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col  cols="2" sm="2" md="2" class="text-right">
              <v-btn
                class="white--text btn-custom mr-4 mt-3"
                color="green darken-1"
                depressed
                @click="search"
              >
                <!-- @click="fetchListStatement(params)" -->
                SEARCH
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
              style="font-family: 'khmer mef1'"
              class="elevation-1"
              item-key="_id"
              
              :headers="headers"
              :footer-props="footerProps"
              :items="users ? users.object : []"
              :server-items-length="users ? users.objectCount : null"
              :sort-desc.sync="dataRequest.descendingFormat"
              :sort-by.sync="dataRequest.sortByFormat"
              :page.sync="dataRequest.page"
              :items-per-page.sync="dataRequest.rowsPerPage"
              @update:page="$emit('getData5')"
              @update:items-per-page="$emit('getData5')"
              @update:sort-desc="$emit('getData5')"
              mobile-breakpoint="0"
          >

            <template v-slot:[`item`]="{ item }">
              <tr>
                <td>{{users.object.indexOf(item) +1 }}</td>
                <td>{{ item.sn_number.name }} </td>
                <td>{{ item.sn_number.desc }} </td>
                <td >{{ item.sn_number.payout }}</td>
                <td >{{ item.fight_no }}</td>
                <td >{{ item.is_win }}</td>
                <td class="amount-bet-color">{{ currencyFormat(item.amount) }}</td>
                <td class="win-color">{{ currencyFormat(item.amount_win) }}</td>
                <td class="lose-color">{{ currencyFormat(item.cast) }}</td>
                <td >{{ item.date }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: ["darkmode", "dialog", "users", "dataRequest","user"],
  data: () => {
    return {
      dialogListUser: false,
      headers : [
          {
              text: 'ល.រ',
              align: 'start',
              value: 'no',
          },
          { text: 'ប្រភេទចាក់ ១', value: 'sn_number.name' },
          { text: 'ប្រ​ភេទ ២', value: 'sn_number.desc' },
          { text: 'រង្វិលជុំ', value: 'sn_number.payout' },
          { text: 'ដៃទី', value: 'sn_number.fight_no' },
          { text: 'លទ្ធិផល', value: 'sn_number.is_win' },
          { text: 'សរុបប្រាក់ភ្នាល់', value: 'amount' },
          { text: 'សរុបឈ្នះ', value: 'amount_win' },
          { text: 'ប្រាក់', value: 'cast' },
          { text: 'កាលបរិច្ឆេទ', value: 'date' },
      ],
      userChild: [],
      footerProps: {
        'items-per-page-options': [10, 20, 50, 100, 200]
      },
      v_startDate: false,
      v_endDate: false,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      v_search: "",
    }
  },
  computed: {
   reportUrl: function(){
     if(this.user){
        if(this.user.role_name == "DEVELOPER") return "getViewPlayerDetail"
        else return null
     }
     else return null
  }},
  methods: {
    search() {
      if (this.user.role_name=="AGENT") this.$emit('getData5', this.v_search)
      else this.$emit('onSearch', this.v_search)
    },
    currencyFormat (num) {
      num = parseFloat(num)
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      // return parseInt(num).toLocaleString('en')
    },
  }
}
</script>

<style scoped>
.amount-bet-color{
  color: goldenrod!important
}
  .win-color{
    color: rgb(57, 175, 214);
    font-weight: bold
  }
  .lose-color{
    color:#BA2D4A;
    font-weight:bold
  }
   
</style>
